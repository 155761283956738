(function () {
  'use strict';

  /* @ngdoc object
   * @name user
   * @description
   *
   */
  angular
  .module('neo.home.user', [
    'ui.router',
    'ngMaterial',
    'neo.home.user.profile'
  ]);
}());
